exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-abendkleider-tsx": () => import("./../../../src/pages/abendkleider.tsx" /* webpackChunkName: "component---src-pages-abendkleider-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-brautkleider-tsx": () => import("./../../../src/pages/brautkleider.tsx" /* webpackChunkName: "component---src-pages-brautkleider-tsx" */),
  "component---src-pages-designer-tsx": () => import("./../../../src/pages/designer.tsx" /* webpackChunkName: "component---src-pages-designer-tsx" */),
  "component---src-pages-dsgvo-tsx": () => import("./../../../src/pages/dsgvo.tsx" /* webpackChunkName: "component---src-pages-dsgvo-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kampagnen-kampangne-1-tsx": () => import("./../../../src/pages/kampagnen/kampangne1.tsx" /* webpackChunkName: "component---src-pages-kampagnen-kampangne-1-tsx" */),
  "component---src-pages-kampagnen-kampangne-2-tsx": () => import("./../../../src/pages/kampagnen/kampangne2.tsx" /* webpackChunkName: "component---src-pages-kampagnen-kampangne-2-tsx" */),
  "component---src-pages-kampagnen-kampangne-3-tsx": () => import("./../../../src/pages/kampagnen/kampangne3.tsx" /* webpackChunkName: "component---src-pages-kampagnen-kampangne-3-tsx" */),
  "component---src-pages-kleider-tsx": () => import("./../../../src/pages/kleider.tsx" /* webpackChunkName: "component---src-pages-kleider-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-traumkleider-tsx": () => import("./../../../src/pages/traumkleider.tsx" /* webpackChunkName: "component---src-pages-traumkleider-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

